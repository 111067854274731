body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
}

#inputArea {
  width: 40%;
  min-width: 440px;
  max-width: 600px;
  height: 30%;
  min-height: 80px;
  max-height: 140px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#form {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#flightPlanDownload {
  width: 50%;
}

#mapid {
  width: 60%;
  height: 100%;
}